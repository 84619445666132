import DateInput from 'Components/UI/Form/DateInput';
import * as PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Breadcrumb from '../Components/UI/Breadcrumb';
import Button from '../Components/UI/Button';
import { actions } from '../Redux/index';
import Dropdown from './Dropdown';
import * as moment from 'moment';
import RichTextEditor from 'react-rte';
import ReactHtmlParser from 'react-html-parser';
import LikesModal from "../Components/LikesModal";

const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
      {label: 'Italic', style: 'ITALIC'},
      {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
      {label: 'Normal', style: 'unstyled'},
      {label: 'Heading Large', style: 'header-one'},
      {label: 'Heading Medium', style: 'header-two'},
      {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'UL', style: 'unordered-list-item'},
      {label: 'OL', style: 'ordered-list-item'}
    ],
  };

const NEWS_TITLE = "Neuigkeiten"

const mapStateToProps = (state) => {
    return {
        locationDropdown: state.data.dropdown.news.location,
        teamDropdown: state.data.dropdown.news.team,
        categoryDropdown: state.data.dropdown.news.category,
        newsModal: state.data.modal.news.news,
        allLocations: state.data.allLocations,
        userGroupList: state.data.userGroupList,
        newsToEdit: state.news.newsToEdit,
        newsList: state.news.list,
        listCount: state.news.listCount,
        currentUser: state.data.currentUser,
        newsModalAfterLogin: state.ui.newsModalAfterLogin,
        categories: state.news.categories,
        preview: state.news.preview,
        countedLikes: state.data.countLikes,
        likes: state.data.likes,
        loadingAnimation: state.advertising.loadingAnimation,
        commentToEdit: state.data.commentToEdit,
        comments: state.data.comments,
    };
};



const mapDispatchToProps = (dispatch) => {
    return {
        updateView: (value) => dispatch(actions.Ui.updateView(value)),
        loadNewsList: (list) => dispatch(actions.News.loadNewsList(list)),
        updateDropdown: (dropdown) => dispatch(actions.Data.updateDropdown(dropdown)),
        updateModal: (modal) => dispatch(actions.Data.updateModal(modal)),
        updateNewsToEdit: (news) => dispatch(actions.News.updateNewsToEdit(news)),
        updateAlert: (alert) => dispatch(actions.Ui.updateAlert(alert)),
        addNews: (news) => dispatch(actions.News.addNews(news)),
        deleteNews: (news) => dispatch(actions.News.deleteNews(news)),
        updateNewsModalAfterLogin: (value) => dispatch(actions.Ui.updateNewsModalAfterLogin(value)),
        updateSeen: page => dispatch(actions.Ui.updateSeen(page)),
        updateCountSeen: seen => dispatch(actions.Ui.updateCountSeen(seen)),
        updateConfirmModal: (modal) => dispatch(actions.Ui.updateConfirmModal(modal)),
        updatePreview: value => dispatch(actions.News.updatePreview(value)),
        updateReference: (value) => dispatch(actions.Training.updateReference(value)),
        countLikes: (value) => dispatch(actions.Data.countLikes(value)),
        toggleLike: (value) => dispatch(actions.Data.toggleLike(value)),
        updateCommentToEdit: comment => dispatch(actions.Data.updateCommentToEdit(comment)),
        updateCommentList: list => dispatch(actions.Data.updateCommentList(list)),
        loadComments: value => dispatch(actions.Data.loadComments(value)),
        addComment: comment => dispatch(actions.Data.addComment(comment)),
        updateComment: comment => dispatch(actions.Data.updateComment(comment)),
        deleteComment: comment => dispatch(actions.Data.deleteComment(comment)),
        loadLikes: (value) => dispatch(actions.Data.likes(value)),
    };
};

class News extends PureComponent {
    state = {
        value: RichTextEditor.createEmptyValue(),
        comment: RichTextEditor.createEmptyValue(),
        likesModalIsVisible: false,
        newsForLikes: null
    }

    static propTypes = {
        user: PropTypes.object,
        updateView: PropTypes.func.isRequired,
        newsList: PropTypes.array,
        loadNewsList: PropTypes.func,
        updateDropdown: PropTypes.func,
        updateModal: PropTypes.func,
        newsToEdit: PropTypes.object,
        updateNewsToEdit: PropTypes.func,
        teamDropdown: PropTypes.object,
        locationDropdown: PropTypes.object,
        listCount: PropTypes.number,
        currentUser: PropTypes.object,
        newsModal: PropTypes.object,
        deleteNews: PropTypes.func,
        categoryDropdown: PropTypes.object,
        categories: PropTypes.array,
        preview: PropTypes.bool,
        updatePreview: PropTypes.func,
        updateReference: PropTypes.func,
        countLikes: PropTypes.func,
        likes: PropTypes.array,
        countedLikes: PropTypes.array,
        updateCommentToEdit: PropTypes.func,
        updateCommentList:PropTypes.func,
        loadComments: PropTypes.func,
        commentToEdit: PropTypes.object,
        addComment: PropTypes.func,
        updateComment: PropTypes.func,
        deleteComment: PropTypes.func,
        countComments: PropTypes.func,
        commentsAmount: PropTypes.array,
        loadLikes: PropTypes.func,
    };

    onChange = (value) => {
        this.setState({...this.state, value});
        if (this.props.onChange) {
          // Send the changes up to the parent component as an HTML string.
          // This is here to demonstrate using `.toString()` but in a real app it
          // would be better to avoid generating a string on each change.
          this.props.onChange(
            value.toString('html')
          );

        }
        this.props.updateNewsToEdit({prop: 'text', val: value.toString('html')})
      };

    showLikesWithUsers = (news) => {
        this.props.loadLikes({entity:'News', entityId: news.id})
        this.toggleVisibilityLikesModal(news)
    }

    toggleVisibilityLikesModal = (news) => {
        this.setState(prevState => ({
            likesModalIsVisible: !prevState.likesModalIsVisible,
            newsForLikes: news
        }));
    }

    triggerFileInput(fileInput) {
        fileInput.click()
    }

    getBase64(file) {
        if (!file) {
            return null;
        }
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.props.updateNewsToEdit({prop:'file', val: reader.result});
            this.props.updateNewsToEdit({prop:'fileName', val: file.name.split(".")[0]})
        };
    }

    componentDidMount() {
        this.props.loadNewsList({ from: 0, view: 'news'});
        this.props.countLikes('News')
    }

    renderFile(file) {
        if (file.indexOf('application/pdf') !== -1) {
            return <embed src={file} width="210" height="297" />;
        } else if (file.indexOf('image/') !== -1) {
            return <img src={file} width="210" height="auto" alt="" />;
        } else {
            return (<i className="fa fa-file-o" width="210" aria-hidden="true" />);
        }
    }

    renderFileForPreview(file) {
        if (file.indexOf('application/pdf') !== -1) {
            return <embed src={file} style={{'maxWidth': '100%', 'minHeight': '450px'}} />;
        } else if (file.indexOf('image/') !== -1) {
            return <img src={file} style={{'maxWidth': '100%'}} alt="" />;
        } else {
            return <img src={file} style={{'maxWidth': '100%'}} alt="" />;
        }
    }

    isNewsInputComplete(){
        if(this.props.newsToEdit.title === '' || this.props.newsToEdit.title === undefined) {
            alert('Der Titel darf nicht leer sein.')
            return false;
        }
        if(this.props.newsToEdit.text === '' || this.props.newsToEdit.text === undefined) {
            alert('Der Text darf nicht leer sein.')
            return false;
        }
        if(this.props.newsToEdit.date === '' || this.props.newsToEdit.date === undefined) {
            alert('Das Datum darf nicht leer sein.')
            return false;
        }
        return true;
    }

    addNews() {
        (this.isNewsInputComplete()) && (
            this.props.addNews({
                'id' : 'newsNews' + Math.floor((Math.random() * 100) + 1),
                'title': this.props.newsToEdit.title,
                'text': this.props.newsToEdit.text,
                'date': moment(this.props.newsToEdit.date).format('DD.MM.YYYY'),
                'teams': this.props.teamDropdown.selectedId || [],
                'locations': this.props.locationDropdown.selectedId || [],
                'category': this.props.categoryDropdown.selectedId || this.props.categories[0].label,
                'file': this.props.newsToEdit.file
            })
        );
    }
    updateNews() {
        (this.isNewsInputComplete()) && (
            this.props.addNews({
                '__identity' : this.props.newsToEdit.__identity,
                'title': this.props.newsToEdit.title,
                'text': this.props.newsToEdit.text,
                'date': moment(this.props.newsToEdit.date).format('DD.MM.YYYY'),
                'teams': this.props.teamDropdown.selectedId || [],
                'locations': this.props.locationDropdown.selectedId || [],
                'category': this.props.categoryDropdown.selectedId || this.props.categories[0].label,
                'file': this.props.newsToEdit.file
            })
        );
    }

    edit(news) {
        this.props.updateModal({
            path: 'news.news',
            prop: 'view',
            val: !this.props.newsModal.view,
        });
        this.props.updateModal({
            path: 'news.news',
            prop: 'action',
            val: 'editNews',
        });

        this.props.updateNewsToEdit({prop: 'title', val: news.title})
        this.props.updateNewsToEdit({prop: 'text', val: news.text.toString('html')})
        this.setState({...this.state, value: RichTextEditor.createValueFromString(news.text, 'html')});
        this.props.updateDropdown({dropdown: 'dropdown.news.team', prop:'selectedId', val:news.teams})
        this.props.updateDropdown({dropdown: 'dropdown.news.location', prop:'selectedId', val:news.locations})
        this.props.updateDropdown({dropdown: 'dropdown.news.category', prop:'selectedId', val:news.category})
        this.props.updateNewsToEdit({prop: 'date',val: moment(news.date).format('YYYY-MM-DD')})
        this.props.updateNewsToEdit({prop: 'resourceUri',val: news.resourceUri})
        this.props.updateNewsToEdit({prop: '__identity',val: news.id})
    }

    deleteNews(news) {
        this.props.updateConfirmModal({prop: 'visible', value: true})
        this.props.updateConfirmModal({prop: 'title', value: 'Neuigkeit löschen?'})
        this.props.updateConfirmModal({prop: 'text', value: 'Möchten Sie wirklich die Neuigkeit löschen?'})
        this.props.updateConfirmModal({prop: 'label', value: "Löschen"})
        this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteNews', param: news}})

    }

    can (...args) {
        return args.some( permission => this.props.currentUser.permissions.includes(permission))
    }

    getThumbsClass(news) {
        let className = "fa fa-thumbs-o-up";
        const newsWithLikes = this.props.countedLikes.find(entityWithLikes => entityWithLikes.id === news.id)
        if(newsWithLikes) {
            className = newsWithLikes.likedByCurrentUser ? "fa fa-thumbs-up" : "fa fa-thumbs-o-up"
        }
        return this.props.loadingAnimation ? 'fa fa-spinner fa-pulse fa-fw' : className
    }

    getCountLikes(news, showLikesModal = false) {
        let count = 0;
        const newsWithLikes = this.props.countedLikes.find(entityWithLikes => entityWithLikes.id === news.id)
        if(newsWithLikes) {
            count = newsWithLikes.count
        }
        return (<small onClick={() => {
            if(showLikesModal && this.can('neuigkeiten_like_benutzernamen_sehen')) {
                this.showLikesWithUsers(news)
            }

        }} className="count-likes-or-comments">{count}</small>)
    }

    deleteComment(comment) {
        // const confirmResult = window.confirm('Möchten Sie wirklich die Werbung löschen?');
        // if (confirmResult === true) {
        //     this.props.deleteAdvertising(advertising);
        // }
        this.props.updateConfirmModal({prop: 'visible', value: true})
        this.props.updateConfirmModal({prop: 'title', value: 'Kommentar löschen'})
        this.props.updateConfirmModal({prop: 'text', value: 'Möchten Sie wirklich den Kommentar löschen?'})
        this.props.updateConfirmModal({prop: 'label', value: "löschen"})
        this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteComment', param: comment}})
    }

    showComments(news, commentId = null) {
        this.edit(news);
        this.props.updateCommentList([]);
        this.props.loadComments({entityId: news.id, entity: 'News'})
        this.props.updateModal({
            path: 'news.news',
            prop: 'view',
            val: true,
        });
        this.props.updatePreview(true);
        this.props.updateCommentToEdit({
            ...this.props.commentToEdit,
            entity: 'News',
            entityId: news.id,
            id: commentId,
        })
    }
    addComment(e) {
        e.preventDefault()
        if(!this.props.commentToEdit.comment.length) {
            return alert('Kommentar darf nicht leer sein')
        }
        if(this.props.commentToEdit.id) {
            this.props.updateComment(this.props.commentToEdit)
        } else {
            this.props.addComment(this.props.commentToEdit)
        }
        this.props.updateCommentToEdit({...this.props.commentToEdit, comment: '', id: null})
    }
    editComment(comment) {
        this.props.updateCommentToEdit(comment)
    }
    closeComments() {
        this.props.updateNewsToEdit({prop: 'resetObj',val: ''})
        this.props.updatePreview(false)
        this.props.updateCommentToEdit({})
        this.closeEditModal()
    }

    closeEditModal() {
        this.props.updateModal({path: 'news.news', prop: 'view', val: !this.props.newsModal.view});
        this.props.updateModal({path: 'news.news', prop: 'action', val: ''});
        this.props.updateNewsToEdit({prop:'resetObj'})
        this.setState({...this.state, value: RichTextEditor.createValueFromString('', 'html')});
        this.props.updateDropdown({dropdown: 'dropdown.news.team', prop:'selectedLabel', val:''})
        this.props.updateDropdown({dropdown: 'dropdown.news.team', prop:'selectedId', val:''})
        this.props.updateDropdown({dropdown: 'dropdown.news.location', prop:'selectedLabel', val:''})
        this.props.updateDropdown({dropdown: 'dropdown.news.location', prop:'selectedId', val:''})
        this.props.updateDropdown({dropdown: 'dropdown.news.category', prop:'selectedLabel', val:''})
        this.props.updateDropdown({dropdown: 'dropdown.news.category', prop:'selectedId', val:''})
    }

    render() {
        let newNewsAllgemein=0, newNewsPersonal=0, newNewsSortiment = 0;
        return (
            <div id="main-side">
                <Breadcrumb path={NEWS_TITLE}>
                    {this.can('neuigkeiten_erstellen') &&
                    <Button label="Neue Nachricht"
                            onClick={() => {
                                this.props.updateModal({
                                    path: 'news.news',
                                    prop: 'view',
                                    val: !this.props.newsModal.view,
                                });
                                this.props.updateModal({
                                    path: 'news.news',
                                    prop: 'action',
                                    val: 'newNews',
                                });
                                this.props.updateNewsToEdit({prop: 'date',val: moment().format('YYYY-MM-DD')})
                                this.setState({...this.state, value: RichTextEditor.createValueFromString('', 'html')});
                            }}
                    />}
                </Breadcrumb>
                <main>
                    <LikesModal
                        toggleVisibilityLikesModal={this.toggleVisibilityLikesModal}
                        likesModalIsVisible={this.state.likesModalIsVisible}
                        news={this.state.newsForLikes}
                    />
                <div id="newsModalAfterLogin" className={this.props.newsModalAfterLogin ? 'modal visible': 'hidden'}>
                        <div className="modal-content width-1000">
                            <div className="headline-counter">
                                <span>
                                    <i className="fa fa-newspaper-o" aria-hidden="true"/> {NEWS_TITLE}
                                </span>
                            </div>
                             <form>
                                <div className="content-wrapper content-form">
                                    <div className="content-wrapper-box news-modal-after-login-wrapper">
                                        {this.props.newsList.filter(news => {
                                            if(this.props.currentUser.lastSeenNewsDate === null) {
                                                return true
                                            }
                                            if (moment(news.date) > moment().set({"hour": 23, "minute": 59})) {
                                                return false
                                            }
                                            if (moment(news.date) > moment(this.props.currentUser.lastSeenNewsDate)) {
                                                return true
                                            }
                                            return false
                                        }).map((news, index) => (
                                                <div className="news-bild" key={index}>
                                                {
                                                    news.resourceUri &&
                                                    <div className="img-wrapper">
                                                        <a href={'/download/resource/'+ news.id} target="_blank" rel="noopener noreferrer" >
                                                            <img style={{'maxWidth': '100%'}} src={news.resourceUri || news.file} alt=""/>
                                                        </a>
                                                    </div>
                                                }
                                                {
                                                    news.file &&
                                                    <div className="img-wrapper">
                                                        <img style={{'maxWidth': '100%'}} src={news.file} alt=""/>
                                                    </div>

                                                }
                                                <div className="news-bild-content">
                                                    <span>{news.dateFormated || news.date}</span>
                                                    <span className="headline-news">{news.title}
                                                        &nbsp;
                                                        <i style={{'cursor': 'pointer'}} onClick={
                                                            () => this.props.toggleLike({entityId: news.id, entity: 'News'})
                                                        }
                                                           className={this.getThumbsClass(news)}
                                                        /> {this.can('likes_can_see') && this.getCountLikes(news)}
                                                        <i style={{'cursor': 'pointer'}} onClick={() => this.showComments(news)} className="fa fa-comments-o"/>
                                                        <small className="count-likes-or-comments">{news.commentCount}</small>

                                                    </span>
                                                    { ReactHtmlParser(news.text) }
                                                </div>
                                            </div>
                                        ))}
                                     </div>
                                </div>

                                <div className="content-wrapper">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-12 large-12">
                                                    <Button label="Alle gelesen" inverted onClick={() => {
                                                        this.props.updateNewsModalAfterLogin(false)
                                                        this.props.updateSeen('news')
                                                        this.props.updateCountSeen({page:'News', value: 0})
                                                    }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>f
                            </form>
                        </div>
                    </div>
                    <div id="newsModal" className={this.props.newsModal.view ? 'modal visible': 'hidden'}>
                        {!this.props.preview &&
                            <div className="modal-content width-1000">
                                <div className="headline-counter">
                                    <span>
                                        <i className="fa fa-newspaper-o" aria-hidden="true"/> Nachricht {this.props.newsModal.action === 'newNews' ? 'erstellen' : 'bearbeiten'}
                                    </span> <span className="close">
                                        <a href="/#-" onClick={(e) => {
                                            e.preventDefault();
                                            this.closeEditModal()
                                            }}>
                                            <i className="fa fa-times-circle-o"/>
                                        </a>
                                    </span>
                                </div>
                                 <form>
                                    <div className="content-wrapper content-form">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <div className="group">
                                                        <input type="text" value={this.props.newsToEdit.title || ''} onChange={(e) => this.props.updateNewsToEdit({prop: 'title', val: e.target.value})} />
                                                        <label>Titel</label>
                                                    </div>
                                                </div>
                                                <div className="small-12 medium-12 large-12">
                                                    <Dropdown dropdown={this.props.categoryDropdown} dropdownList={this.props.categories} itemIdProp={'label'} itemLabelProp={'label'} defaultSelectedLabel={this.props.categories[0].label} multiple={false}/>
                                                </div>
                                                <div className="small-12 medium-12 large-12">
                                                    <div className="group">
                                                    <RichTextEditor
                                                        className={'rte-font'}
                                                        value={this.state.value}
                                                        onChange={this.onChange}
                                                        toolbarConfig={toolbarConfig}
                                                    />
                                                    {/**<textarea value={this.props.newsToEdit.text || ''} onChange={(e) => this.props.updateNewsToEdit({prop: 'text', val: e.target.value})}/> */}
                                                        <label>Nachricht</label>
                                                    </div>
                                                </div>
                                                <div className="small-12 medium-6 large-6">
                                                    <Dropdown dropdown={this.props.locationDropdown} dropdownList={this.props.allLocations} itemIdProp={'id'} itemLabelProp={'label'} defaultSelectedLabel={'Alle'} multiple={true}/>
                                                </div>
                                                <div className="small-12 medium-6 large-6">
                                                        <Dropdown dropdown={this.props.teamDropdown} dropdownList={this.props.userGroupList} itemIdProp={'name'} itemLabelProp={'label'} defaultSelectedLabel={'Alle'} multiple={true}/>
                                                </div>
                                                <div className="small-12 medium-6 large-6">
                                                    <div className="group">
                                                        <DateInput
                                                            minDate={new Date(moment().subtract(5, "months"))}
                                                            selected={this.props.newsToEdit.date
                                                                ? new Date(this.props.newsToEdit.date)
                                                                : null}
                                                            onChange={(date) => this.props.updateNewsToEdit({
                                                                prop: 'date',
                                                                val: moment(date.toLocaleString('de-DE'), 'DD.MM.YYYY').format('YYYY-MM-DD'),
                                                            })}
                                                            value={moment(this.props.newsToEdit.date).format('DD.MM.YYYY')}
                                                        />
                                                        <label>Veröffentlichungsdatum</label>
                                                    </div>
                                                </div>
                                                <div className="small-12 medium-6 large-6">
                                                    <div className="group">
                                                        <input
                                                            type="text"
                                                            readOnly
                                                            value={this.props.newsToEdit.fileName || ''}
                                                            onClick={() => (this.triggerFileInput(this.refs.fileInput))}
                                                        />
                                                        <input type="file" ref="fileInput" onChange={(e) => {e.preventDefault(); this.getBase64(e.target.files[0]);}} />
                                                        <label>Bild</label>
                                                    </div>
                                                </div>
                                                {this.props.newsToEdit.file &&
                                                    <div className="FileInput__thumbnail-list">
                                                        <div className="itemPreview">
                                                            <div className="previewItemWrapper">
                                                                {this.renderFile(this.props.newsToEdit.file)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {!this.props.newsToEdit.file && this.props.newsToEdit.resourceUri &&
                                                    <div className="FileInput__thumbnail-list">
                                                        <div className="itemPreview">
                                                            <div className="previewItemWrapper">
                                                                <img src={this.props.newsToEdit.resourceUri} width="210" height="auto" alt="newsteaser" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="content-wrapper">
                                        <div className="content-wrapper-box">
                                            <div className="grid-wrapper">
                                                <div className="small-12 medium-12 large-12">
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-around'
                                                    }
                                                    }>
                                                    {
                                                        this.props.newsModal.action === 'newNews' &&
                                                        <Button label="Erstellen" inverted onClick={() => this.addNews()}/>
                                                    }
                                                    {
                                                        this.props.newsModal.action === 'editNews' &&
                                                        <Button label="Speichern" inverted onClick={() => this.updateNews()}/>
                                                    }

                                                    <Button label="Vorschau" inverted onClick={() => this.props.updatePreview(true)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        }
                        {this.props.preview &&
                            <div className="news-preview modal-content width-1000">
                            <div className="headline-counter">
                                <span>
                                    <i className="fa fa-newspaper-o" aria-hidden="true"/> Vorschau
                                </span>
                            </div>
                            <form>
                                <div className="content-wrapper content-form">
                                    <div className="content-wrapper-box news-modal-after-login-wrapper">
                                            <div className="news-bild" >
                                                {
                                                    (this.props.newsToEdit.file || this.props.newsToEdit.resourceUri)  &&
                                                    <div className="img-wrapper">
                                                        {this.renderFileForPreview(this.props.newsToEdit.file || this.props.newsToEdit.resourceUri)}
                                                    </div>

                                                }
                                                <div className="news-bild-content">
                                                    <span>{moment(this.props.newsToEdit.date).format('DD.MM.YYYY')}</span>
                                                    <span className="headline-news">{this.props.newsToEdit.title}</span>
                                                    { ReactHtmlParser(this.props.newsToEdit.text) }
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                {this.props.commentToEdit.entityId &&
                                <div className="content-wrapper">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-12 large-12">
                                                <div className="group">
                                                    <textarea className="small-textarea"
                                                              maxLength="300"
                                                              rows="3"
                                                              value={this.props.commentToEdit.comment || ''}
                                                              onChange={(e) => this.props.updateCommentToEdit({
                                                                  ...this.props.commentToEdit,
                                                                  comment: e.target.value
                                                              })}
                                                    />
                                                    {this.props.commentToEdit.id &&
                                                        <label>Kommentar von {this.props.commentToEdit.createdAtFormated} bearbeiten</label>
                                                    }
                                                    {!this.props.commentToEdit.id &&
                                                    <label>Kommentar</label>
                                                    }

                                                    <button className="add-comment" onClick={(e) => this.addComment(e)}
                                                    ><i className={'fa fa-paper-plane fa-2x'}></i></button>
                                                </div>
                                            </div>


                                            <div className="small-12 medium-12 large-12">
                                                {this.props.loadingAnimation &&
                                                <div>
                                                    <p>Kommentare werden geladen <i
                                                        className={'fa fa-spinner fa-pulse fa-fw'}></i></p>
                                                </div>
                                                }
                                                {
                                                    !this.props.loadingAnimation && !this.props.comments &&
                                                    <div>
                                                        <p>Noch keine Kommentare</p>
                                                    </div>
                                                }
                                                {this.props.comments.map(comment =>
                                                    <div className="comment">
                                                        <div className="comment-title">
                                                            {comment.userName} {comment.createdAtFormated}
                                                            &nbsp;
                                                            {(
                                                                ((this.props.currentUser.id === comment.userId) && this.can('comments_can_edit_own'))
                                                                || this.can('comments_can_edit_global')
                                                            ) &&
                                                                <i className="fa fa-pencil" on onClick={() =>  this.props.updateCommentToEdit(comment)}></i>
                                                            }
                                                            &nbsp;
                                                            {
                                                                this.can('comments_can_delete') &&
                                                                <i className="fa fa-trash" on onClick={() =>  this.deleteComment(comment)}></i>
                                                            }
                                                        </div>

                                                        <div className="comment-content">{comment.comment}</div>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                <div className="content-wrapper">
                                    <div className="content-wrapper-box">
                                        <div className="grid-wrapper">
                                            <div className="small-12 medium-12 large-12">
                                                {
                                                    this.props.commentToEdit.entityId &&
                                                    <Button label="Schließen" inverted onClick={() => this.closeComments()}/>
                                                }
                                                { !this.props.commentToEdit.entityId &&
                                                <Button label="Zurück" inverted onClick={() => this.props.updatePreview(false)}/>
                                                }


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        }
                    </div>
                    <div className="content-wrapper banner-big news">
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <div className="site-wrapper">
                                        <div className="headline-counter">
                                            <span><i className="fa fa-newspaper-o" aria-hidden="true"></i> {NEWS_TITLE}</span>
                                        </div>
                                        <Tabs>
                                            <TabList>
                                                {this.props.categories.map(tab => <Tab key={tab.label}>{tab.label}</Tab>)}
                                            </TabList>
                                            {this.props.categories.map(tab => (
                                                <TabPanel key={tab.label}>
                                                    {this.props.newsList.filter(n => n.category === tab.label).map((news, index) => {
                                                            if(news.category==='Allgemein'){
                                                                newNewsAllgemein++;
                                                            }
                                                            else if(news.category==='Personal'){
                                                                newNewsPersonal++;
                                                            }
                                                            else if(news.category==='Sortiment'){
                                                                newNewsSortiment++;
                                                            }
                                                        if(news.id === 'defaultId') {
                                                            return (
                                                                <a href="/#-" className="dokument-item" key={index}>
                                                                    <div className="default-list-item">
                                                                        <span></span>
                                                                    </div>
                                                                </a>
                                                            )
                                                        } else {
                                                            return (
                                                                <div className="news-bild" key={index}>
                                                                    {
                                                                        news.resourceUri &&
                                                                        <div className="img-wrapper">
                                                                            <a href={'/download/resource/'+ news.id} target="_blank" rel="noopener noreferrer" >
                                                                                <img style={{'maxWidth': '100%'}} src={news.resourceUri || news.file} alt=""/>
                                                                            </a>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        news.file &&
                                                                        <div className="img-wrapper">
                                                                            <img style={{'maxWidth': '100%'}} src={news.file} alt=""/>
                                                                        </div>

                                                                    }
                                                                    <div className="news-bild-content">
                                                                        <span>{news.dateFormated || news.date}</span>
                                                                        <span className="headline-news">
                                                                            {news.title} &nbsp;
                                                                            <i style={{'cursor': 'pointer'}} onClick={
                                                                                () => this.props.toggleLike({
                                                                                    entityId: news.id,
                                                                                    entity: 'News'
                                                                                })
                                                                            }
                                                                               className={this.getThumbsClass(news)}
                                                                            />
                                                                            {this.can('likes_can_see') && this.getCountLikes(news, true)}
                                                                            <i style={{'cursor': 'pointer'}}
                                                                               onClick={() => this.showComments(news)}
                                                                               className="fa fa-comments-o"/>
                                                                            <small
                                                                                className="count-likes-or-comments">{news.commentCount}</small>


                                                                        </span>
                                                                        <div> {ReactHtmlParser(news.text)} </div>
                                                                        {
                                                                            news.linkId &&
                                                                            <div>
                                                                                <a href="/"
                                                                                   onClick={(e) => {
                                                                                       e.preventDefault()
                                                                                       this.props.updateView('Training');
                                                                                       this.props.updateReference(news.linkId);
                                                                                   }}
                                                                                >
                                                                                    Zur Veranstaltung
                                                                                </a>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="delete-button visible">
                                                                        {this.can('neuigkeiten_bearbeiten') &&
                                                                        <>
                                                                            <i style={{ 'cursor': 'pointer' }}
                                                                               onClick={() => {
                                                                                   this.edit(news);
                                                                               }}
                                                                               className="fa fa-2x fa-pencil"
                                                                            /> &nbsp;
                                                                        </>}
                                                                        {this.can('neuigkeiten_l_schen') &&
                                                                        <i onClick={() => this.deleteNews(news)}
                                                                           className="fa fa-2x fa-trash"
                                                                        />}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                    {(newNewsAllgemein===0 && tab.label==='Allgemein') && (<div className="noNewsFound"><p>Keine Neuigkeiten vorhanden</p></div>)}
                                                    {(newNewsPersonal===0 && tab.label==='Personal') && (<div className="noNewsFound"><p>Keine Neuigkeiten vorhanden</p></div>)}
                                                    {(newNewsSortiment===0 && tab.label==='Sortiment') && (<div className="noNewsFound"><p>Keine Neuigkeiten vorhanden</p></div>)}
                                                </TabPanel>
                                            ))}
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {
                        this.props.listCount > this.props.newsList.length &&
                        <div className="content-wrapper inner-ver">
                            <div className="content-wrapper-box">
                                <div className="grid-wrapper">
                                    <div className="small-12 medium-12 large-12">
                                        <Button
                                            label="Weitere Neuigkeiten anzeigen"
                                            inverted
                                            onClick={() => {
                                                this.props.loadNewsList({ 'from': this.props.newsList.length });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }


                </main>
                <footer id="footer-main">

                </footer>
            </div>


        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(News);
